import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import Notifikasi from '../module/notifikasi'
import LogoMySMK from '../image/MySMK.png'

import { MdMenu } from 'react-icons/md'
import useShowNotif from '../hook/useShowNotif'
import { IoIosNotifications } from 'react-icons/io'
import useNotif from '../hook/useNotif'
import useList from '../hook/useList'
import { syncToken } from '../api/axiosClient'
import SidebarSiswa from './Sidebar/sidebarSiswa'
import { useQuery } from 'react-query'
import { getProfile } from '../api/siswa/profile'
import { useDispatch } from 'react-redux'
import { setProfile } from '../redux/actions'
import { IoLogOutOutline } from 'react-icons/io5'
import { LoadingPage, ModalLogout } from '../components'

export default function Siswa() {
	let { pathname } = useLocation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	React.useEffect(() => {
		document.title = 'MySMK'
		// requestToken();
	})

	syncToken()
	const { identitas: data } = useList()
	let { isLoading } = useQuery(['/santri/profile'], () => getProfile(), {
		onSuccess: (response) => {
			dispatch(setProfile(response))
		},
		refetchOnWindowFocus: false,
		select: (response) => {
			return response.data.siswa
		},
	})

	const [sidebar, setSidebar] = React.useState(false)
	const [notif, setNotif] = React.useState(false)
	let [showNotif, setShowNotf] = useShowNotif()
	let { jumlah } = useNotif()

	const [open, setOpen] = React.useState(false)

	React.useEffect(() => {
		if (pathname === '/siswa') {
			navigate('/siswa/dashboard')
		}
	}, [pathname, navigate])

	if (isLoading) {
		return <LoadingPage />
	}

	return (
		<>
			<ModalLogout open={open} setOpen={setOpen} />

			<div className="h-screen antialiased text-gray-700 overflow-hidden  ">
				{/* tablet */}
				<header className="h-[8%] xl:hidden  lg:h-1/12 xl:h-1/12 w-fullx items-center grid grid-cols-10 gap-x-5 border   ">
					<div className=" col-span-4 pl-5 h-full w-full   relative flex items-center">
						<img className="absolute" style={{ maxWidth: '60%', maxHeight: '60%' }} src={LogoMySMK} alt={LogoMySMK} />
					</div>

					<div className="col-span-6 xl:col-span-2 flex items-center justify-end space-x-5   h-full w-full relative ">
						<div className="block xl:hidden  xl:h-12 xl:w-12 w-10 h-10 ">
							<button
								className="mb-5"
								onClick={() => {
									setSidebar(!sidebar)
								}}
							>
								<MdMenu className="w-10 h-10" />
							</button>
						</div>
					</div>
				</header>
				{/* tablet */}

				<main className="flex h-[92%] lg:h-11/12 xl:h-11/12 xl:h-full">
					<div
						className={` w-full h-screen xl:rounded-r-3xl bg-gray-50 text-white xl:bg-gray-50 pl-2  ${
							!sidebar ? 'transform -translate-x-full -z-50   xl:-translate-x-0' : 'transform -translate-x-0 z-10  transition  duration-500 '
						} h-full fixed top-0 bottom-0 xl:w-[200px]  xl:relative flex flex-col `}
					>
						{/* laptop */}
						<div className="hidden xl:block mb-8 mt-4 pl-3">
							{/* <img style={{ maxWidth: '60%', maxHeight: '60%' }} src={LogoMySMK} alt={LogoMySMK} /> */}
							<img className="w-[65%]" src={LogoMySMK} alt={LogoMySMK} />
						</div>
						{/* laptop */}

						<div className="h-full xl:flex-1 flex flex-col xl:overflow-y-auto">
							<SidebarSiswa setSidebar={setSidebar} />
						</div>

						{/* laptop */}
						<div className="mt-5 mb-4 ml-2 hidden xl:block">
							<LogoutButton
								onClick={() => {
									return setOpen(true)
								}}
								title={'Logout'}
								logo={<IoLogOutOutline className={`h-6 w-6 text-gray-900 group-hover:text-[#18a558]`} />}
							/>
						</div>
						{/* laptop */}
					</div>

					<div className={`content relative  h-full w-full   overflow-auto xl:overflow-hidden ${showNotif ? 'xl:w-[100%]' : 'xl:w-[100%]'}`}>
						{/* <div className=" bg-blue-400">
						<button
							onClick={() => {
								return setShowNotf(!showNotif)
							}}
							className={` rounded-full p-2 hidden xl:block absolute  right-5 top-1 z-50 ${showNotif ? 'bg-red-400' : ''}`}
						>
							<IoIosNotifications className={`h-8 w-8 ${showNotif ? 'text-white' : ''}`} />
							{jumlah > 0 && (
								<div style={{ fontSize: '8px' }} className="w-4 h-4 pt-1   absolute z-10 top-1 right-2 bg-red-400 text-white rounded-full">
									{jumlah}
								</div>
							)}
						</button>
					</div> */}

						<div id="sidebar" className="h-full w-full">
							<Outlet data={data} />
						</div>
					</div>

					{/* notif */}
					{/* <div
					className={` w-full h-full bg-[#46C7C7] text-white xl:text-gray-700 xl:bg-white    pl-0 xl:pl-2      ${
						!notif ? 'transform -translate-y-full xl:-translate-y-0' : 'transform -translate-y-0 transition  duration-500 '
					} h-full z-10 fixed top-0 bottom-0 ${!showNotif ? 'xl:w-[20%]' : 'xl:hidden'} xl:relative  `}
				>
					<Notifikasi setNotif={setNotif} />
				</div> */}
				</main>
			</div>
		</>
	)
}

// className={`w-full h-full flex z-10 fixed top-0 bottom-0 xl:w-3/12  xl:relative text-white`}

export function LogoutButton({ to, title, logo, onClick }) {
	let { pathname } = useLocation()
	let url = pathname.split('/')[2]

	return (
		<button onClick={onClick} className="flex group flex-grow-0 items-center font-extrabold">
			<div>{logo}</div>
			<p className={`font-extrabold ml-2 text-xs text-gray-900  font-poppins text-left ${url === to ? 'text-white-400 ' : 'text-gray-600 '} font-bold group-hover:text-[#18a558]`}>{title}</p>
		</button>
	)
}
